import { template as template_05179ccc361241a9a96bf66fda7c9780 } from "@ember/template-compiler";
const WelcomeHeader = template_05179ccc361241a9a96bf66fda7c9780(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
