import { template as template_db22de33c0864672893509bdca6f59dd } from "@ember/template-compiler";
const FKControlMenuContainer = template_db22de33c0864672893509bdca6f59dd(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
