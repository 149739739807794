import { template as template_69262dae56f44735ba43e37b3bc3f719 } from "@ember/template-compiler";
const FKLabel = template_69262dae56f44735ba43e37b3bc3f719(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
