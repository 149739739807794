import { template as template_b5053dd7d7b64fbb83821cca41b0de2d } from "@ember/template-compiler";
const FKText = template_b5053dd7d7b64fbb83821cca41b0de2d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
